import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  outerWrapper: {
    width: '100%',
    backgroundColor: '#f3f3f3'
  },
  root: {
    padding: '0px',
    display: 'flex',
    position: 'relative',
    width: '100%'
  },
  bgImage: {
    width: '100%'
  }
});

const ArticleHero = ({ hero_background_image }) => {
  const classes = useStyles();

  return (
    <div className={classes.outerWrapper}>
      <div className={classes.root}>
        <img
          className={classes.bgImage}
          src={hero_background_image.url}
          alt={hero_background_image.alt}
        />
      </div>
    </div>
  );
};

ArticleHero.propTypes = {
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired
};

ArticleHero.defaultProps = {};

export default ArticleHero;
